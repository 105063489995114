<template>
  <div class="card mb-3">
    <div class="card-header">
      <strong>Events <span v-if="eventsCount !== null">({{ eventsCount }})</span></strong>
    </div>
    <div class="card-body text-center">
      <small>
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i> Events are stored for 90 days and then deleted.
      </small>
    </div>
    <BaseSpinner :loading="loading">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th class="w-50">Event</th>
            <th>Date</th>
            <th>Webhook Attempts</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="event in events"
            :key="event.id"
          >
            <td>
              <router-link :to="{ name: 'events-id', params: { id: event.id }}" class="text-dark">
                <span v-if="event.name === 'validation.succeeded'">Validation succeeded for <strong>{{ event.payload.query }}</strong></span>
                <span v-if="event.name === 'batch.succeeded'">Batch succeeded for <strong>{{ event.payload.name }}</strong></span>
              </router-link>
            </td>
            <td>
              {{ convertDateTime(event.created) }}
            </td>
            <td>
              <span v-if="event.attempts_count">{{ event.attempts_count }}</span>
              <small v-else>
                <span class="text-muted">Not Configured</span>
              </small>
            </td>
          </tr>
          <tr v-if="!events.length && !loading">
            <td class="text-muted text-center" colspan="3">
              <i class="fad fa-empty-set mr-1"></i> No events have been generated yet.
            </td>
          </tr>
        </tbody>
      </table>
    </BaseSpinner>
    <div class="card-footer">
      <BasePagination
        v-model="filters.page"
        :hasMore="hasMore"
        :pageCount="Math.ceil(eventsCount / filters.limit)"
      />
    </div>
  </div>
</template>

<script>
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BasePagination,
    BaseSpinner,
  },

  data() {
    return {
      loading: false,
      hasMore: false,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      events: [],
      eventsCount: null,
    }
  },

  mounted() {
    this.listEvents()
  },

  methods: {
    async listEvents() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/events`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: { page: this.filters.page },
        })

        this.hasMore = data.has_more
        this.events = data.events
        this.eventsCount = data.events_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      if (this.filters.page !== 1) {
        this.$router.replace({ name: 'events' })
      } else {
        this.listEvents()
      }
    },
    'filters.page'(value) {
      this.$router.push({ name: 'events', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
