<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else class="card mb-3">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'events' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Events</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ event && event.id }}
          </li>
        </ul>
      </div>
      <table class="table mb-0">
        <thead>
          <tr class="thead-light">
            <th class="w-25">Key</th>
            <th class="w-75">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-muted">Name</td>
            <td><code>{{ event.name }}</code></td>
          </tr>
          <tr>
            <td class="text-muted align-top">Payload Object</td>
            <td>
              <span v-if="event.name === 'validation.succeeded' || event.name === 'validation.failed'"><router-link :to="{ name: 'validations-id', params: { id: event.payload.id }}">{{ event.payload.id }}</router-link></span>
              <span v-if="event.name === 'batch.succeeded' || event.name === 'batch.failed'"><router-link :to="{ name: 'batches-id', params: { id: event.payload.id }}">{{ event.payload.id }}</router-link></span>
            </td>
          </tr>
          <tr>
            <td class="text-muted align-top">Created</td>
            <td>{{ convertDateTime(event.created) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="card-footer text-muted">
        <small>Last updated {{ convertDateTime(event.updated) }}</small>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Raw JSON</strong>
      </div>
      <pre class="rounded-bottom p-4"><code>{{ event }}</code></pre>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      event: null,
    }
  },

  async mounted() {
    await this.retrieveEvent()
  },

  methods: {
    async retrieveEvent() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/events/${ this.$route.params.id }`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
        })

        this.event = data
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            this.$router.push({ name: '404' })
          }

          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'events' })
    },
  },
}
</script>
